/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import { createAction } from 'redux-actions';

export const GET_HEADER = createAction('DASHBOARD/GET_HEADER');
export const GET_PROFIT_CHART = createAction('DASHBOARD/GET_PROFIT_CHART');
export const GET_USER_CHART = createAction('DASHBOARD/GET_USER_CHART');
export const HANDLE_SEARCH_CHART = createAction('DASHBOARD/HANDLE_SEARCH_CHART');

export const CLEAR = createAction('DASHBOARD/CLEAR_QUESTION');
