/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import { CONFIG } from '../../config/constant';
import * as axios from '../../services/axios';

export const APIS_LOGIN = async (data) =>
  await axios.send({
    url: `${CONFIG.endpoint}/patient/login-system`,
    method: axios.EMethod.POST,
    data
  });

export const APIS_CHANGE_PASS = async () => {};

export const APIS_FIND_ONE_USER_INFOR = async () =>
  await axios.send({
    url: `${CONFIG.endpoint}/patient/profile`,
    method: axios.EMethod.GET
  });
export const APIS_FORGOT_PASSWORD = async (data) =>
  await axios.send({
    url: `${CONFIG.endpointV2}/patient/receive-password`,
    method: axios.EMethod.POST,
    data
  });

export const APIS_NEW_PASSWORD = async (data) =>
  await axios.send({
    url: `${CONFIG.endpointV2}/patient/reset-password`,
    method: axios.EMethod.POST,
    data
  });
