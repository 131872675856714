/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import { createAction } from 'redux-actions';

export const BOX_LAYOUT = createAction('AUTHENTICATION_BOX_LAYOUT');
export const CHANGE_LAYOUT = createAction('AUTHENTICATION_CHANGE_LAYOUT');
export const CHANGE_SUB_LAYOUT = createAction('AUTHENTICATION_CHANGE_SUB_LAYOUT');
export const COLLAPSE_MENU = createAction('AUTHENTICATION_COLLAPSE_MENU');
export const COLLAPSE_TOGGLE = createAction('AUTHENTICATION_COLLAPSE_TOGGLE');
export const CONFIG_BLOCK = createAction('AUTHENTICATION_CONFIG_BLOCK');
export const HEADER_BACK_COLOR = createAction('AUTHENTICATION_HEADER_BACK_COLOR');
export const HEADER_FIXED_LAYOUT = createAction('AUTHENTICATION_HEADER_FIXED_LAYOUT');
export const LAYOUT_TYPE = createAction('AUTHENTICATION_LAYOUT_TYPE');
export const LAYOUT6_BACKGROUND = createAction('AUTHENTICATION_LAYOUT6_BACKGROUND');
export const NAV_ACTIVE_LIST_COLOR = createAction('AUTHENTICATION_NAV_ACTIVE_LIST_COLOR');
export const NAV_BRAND_COLOR = createAction('AUTHENTICATION_NAV_BRAND_COLOR');
export const NAV_BACK_COLOR = createAction('AUTHENTICATION_NAV_BACK_COLOR');
export const NAV_BACK_IMAGE = createAction('AUTHENTICATION_NAV_BACK_IMAGE');
export const NAV_DROPDOWN_ICON = createAction('AUTHENTICATION_NAV_DROPDOWN_ICON');
export const NAV_COLLAPSE_LEAVE = createAction('AUTHENTICATION_NAV_COLLAPSE_LEAVE');
export const NAV_CONTENT_LEAVE = createAction('AUTHENTICATION_NAV_CONTENT_LEAVE');
export const NAV_FIXED_LAYOUT = createAction('AUTHENTICATION_NAV_FIXED_LAYOUT');
export const NAV_ICON_COLOR = createAction('AUTHENTICATION_NAV_ICON_COLOR');
export const NAV_LIST_ICON = createAction('AUTHENTICATION_NAV_LIST_ICON');
export const NAV_LIST_TITLE_COLOR = createAction('AUTHENTICATION_NAV_LIST_TITLE_COLOR');
export const NAV_LIST_TITLE_HIDE = createAction('AUTHENTICATION_NAV_LIST_TITLE_HIDE');
export const RESET = createAction('AUTHENTICATION_RESET');
export const RTL_LAYOUT = createAction('AUTHENTICATION_RTL_LAYOUT');

export const LOGIN = createAction('AUTHENTICATION_LOGIN');
export const LOGIN_SUCCESS = createAction('AUTHENTICATION_LOGIN_SUCCESS');
export const LOGIN_FAILED = createAction('AUTHENTICATION_LOGIN_FAILED');
export const LOGOUT = createAction('AUTHENTICATION_LOGOUT');
export const ACCOUNT_INITIALISE = createAction('AUTHENTICATION_ACCOUNT_INITIALISE');
export const FIREBASE_STATE_CHANGED = createAction('AUTHENTICATION_FIREBASE_STATE_CHANGED');

export const CHANGE_PASSWORD = createAction('AUTHENTICATION_CHANGE_PASSWORD');
export const CHANGE_PASSWORD_SUCCESS = createAction('AUTHENTICATION_CHANGE_PASSWORD_SUCCESS');
export const CHANGE_PASSWORD_FAILED = createAction('AUTHENTICATION_CHANGE_PASSWORD_FAILED');

export const FORGOT_PASSWORD = createAction('AUTHENTICATION_FORGOT_PASSWORD');
export const FORGOT_PASSWORD_SUCCESS = createAction('AUTHENTICATION_FORGOT_PASSWORD_SUCCESS');
export const FORGOT_PASSWORD_FAILED = createAction('AUTHENTICATION_FORGOT_PASSWORD_FAILED');

export const NEW_PASSWORD = createAction('AUTHENTICATION_NEW_PASSWORD');
export const NEW_PASSWORD_SUCCESS = createAction('AUTHENTICATION_NEW_PASSWORD_SUCCESS');
export const NEW_PASSWORD_FAILED = createAction('AUTHENTICATION_NEW_PASSWORD_FAILED');
