/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */

import * as actions from './actions';
import sagas from './sagas';
import reducer, { name } from './reducer';
export { name, actions, sagas };

export default reducer;
