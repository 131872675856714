import {
  MENU_USER,
  ROUTER_USER,
  MENU_REQUEST_DOCTOR,
  ROUTER_REQUEST_DOCTOR,
  MENU_DASHBOARD_ADMIN,
  ROUTER_DASHBOARD_ADMIN,
  ROUTER_TRANSACTION,
  MENU_TRANSACTION,
  ROUTER_QUESTION,
  MENU_QUESTION,
  MENU_ITEM,
  ROUTER_ITEM,
  ROUTER_KYC_USER,
  MENU_KYC_USER
} from './screens/Admin/router';
import {
  MENU_SPECIALIST,
  ROUTER_SPECIALIST,
  ROUTER_NATION,
  MENU_NATION,
  MENU_PROVINCE,
  ROUTER_PROVINCE,
  MENU_HOSPITAL,
  ROUTER_HOSPITAL,
  MENU_LANGUAGE,
  ROUTER_LANGUAGE,
  MENU_CURRENCY,
  ROUTER_CURRENCY,
  ROUTER_NEW,
  MENU_NEW,
  MENU_CATEGORY,
  ROUTER_CATEGORY,
  ROUTER_DISTRICT,
  MENU_DISTRICT
} from './screens/Modules/router';

const menuItems = {
  items: [
    {
      id: 'navigation',
      title: '',
      type: 'group',
      icon: 'icon-navigation',
      children: [
        {
          id: MENU_NEW,
          title: 'New',
          type: 'item',
          icon: 'feather icon-book',
          url: ROUTER_NEW,
          permission: 'employees',
          external: true
        },
        {
          id: MENU_DASHBOARD_ADMIN,
          title: 'Dashboard',
          type: 'item',
          icon: 'feather icon-pie-chart',
          url: ROUTER_DASHBOARD_ADMIN,
          permission: 'employees',
          external: true
        },
        {
          id: MENU_QUESTION,
          title: 'Questions',
          type: 'item',
          icon: 'feather icon-help-circle',
          url: ROUTER_QUESTION,
          permission: 'employees',
          external: true
        },
        {
          id: MENU_USER,
          title: 'User',
          type: 'item',
          icon: 'feather icon-user',
          url: ROUTER_USER,
          permission: 'employees',
          external: true
        },
        {
          id: 'authentication',
          title: 'Authentication',
          type: 'collapse',
          icon: 'feather icon-inbox',
          url: ROUTER_REQUEST_DOCTOR,
          permission: 'employees',
          external: true,
          children: [
            {
              id: MENU_KYC_USER,
              title: 'KYC User',
              type: 'item',
              icon: 'feather icon-map',
              url: ROUTER_KYC_USER,
              permission: 'employees',
              external: true
            },
            {
              id: MENU_REQUEST_DOCTOR,
              title: 'Request Doctor',
              type: 'item',
              icon: 'feather icon-inbox',
              url: ROUTER_REQUEST_DOCTOR,
              permission: 'employees',
              external: true
            }
          ]
        },
        {
          id: MENU_TRANSACTION,
          title: 'Transaction',
          type: 'item',
          icon: 'feather icon-credit-card',
          url: ROUTER_TRANSACTION,
          permission: 'employees',
          external: true
        },
        {
          id: MENU_ITEM,
          title: 'Items',
          type: 'item',
          icon: 'feather icon-layers',
          url: ROUTER_ITEM,
          permission: 'employees',
          external: true
        },
        {
          id: 'setting',
          title: 'Setting System',
          type: 'collapse',
          icon: 'feather icon-settings',
          url: ROUTER_NATION,
          // permission: 'employees',
          external: true,
          children: [
            {
              id: MENU_NATION.toLocaleLowerCase().replace(/ /g, '_'),
              title: MENU_NATION,
              type: 'item',
              icon: 'feather icon-map',
              url: ROUTER_NATION,
              permission: 'employees',
              external: true
            },
            {
              id: MENU_PROVINCE.toLocaleLowerCase().replace(/ /g, '_'),
              title: MENU_PROVINCE,
              type: 'item',
              icon: 'feather icon-map-pin',
              url: ROUTER_PROVINCE,
              permission: 'employees',
              external: true
            },
            {
              id: MENU_DISTRICT.toLocaleLowerCase().replace(/ /g, '_'),
              title: MENU_DISTRICT,
              type: 'item',
              icon: 'feather icon-map-pin',
              url: ROUTER_DISTRICT,
              permission: 'employees',
              external: true
            },
            {
              id: MENU_HOSPITAL.toLocaleLowerCase().replace(/ /g, '_'),
              title: MENU_HOSPITAL,
              type: 'item',
              icon: 'feather icon-shield',
              url: ROUTER_HOSPITAL,
              permission: 'employees',
              external: true
            },
            {
              id: MENU_LANGUAGE.toLocaleLowerCase().replace(/ /g, '_'),
              title: MENU_LANGUAGE,
              type: 'item',
              icon: 'feather icon-globe',
              url: ROUTER_LANGUAGE,
              permission: 'employees',
              external: true
            },
            {
              id: MENU_CURRENCY.toLocaleLowerCase().replace(/ /g, '_'),
              title: MENU_CURRENCY,
              type: 'item',
              icon: 'feather icon-refresh-ccw',
              url: ROUTER_CURRENCY,
              permission: 'employees',
              external: true
            },
            {
              id: MENU_SPECIALIST,
              title: 'Specialist',
              type: 'item',
              icon: 'feather icon-book',
              url: ROUTER_SPECIALIST,
              permission: 'employees',
              external: true
            },
            {
              id: MENU_CATEGORY,
              title: 'Category',
              type: 'item',
              icon: 'feather icon-menu',
              url: ROUTER_CATEGORY,
              permission: 'employees',
              external: true
            }
          ]
        }
      ]
    }
  ]
};

export default menuItems;
