/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import * as actions from './actions';
import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import moment from 'moment';

export const initialState = freeze({
  isLoggedIn: false,
  isInitialised: false,
  user: null,
  dataSignUp: {
    username: '',
    password: ''
  },
  gmail: null,
  isLoading: false
});

export const name = 'AUTHENTICATION';

export default handleActions(
  {
    [actions.ACCOUNT_INITIALISE]: (state, action) => {
      const { isLoggedIn, user } = action.payload;
      return freeze({
        ...state,
        isLoggedIn,
        isInitialised: true,
        user
      });
    },
    [actions.LOGIN_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoggedIn: true,
        user: action.payload
      });
    },
    [actions.LOGIN_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoggedIn: false,
        user: initialState.user
      });
    },
    [actions.LOGOUT]: (state, action) => {
      return freeze({
        ...state,
        isLoggedIn: false,
        isInitialised: true,
        user: null
      });
    },
    [actions.CHANGE_PASSWORD]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true
      });
    },
    [actions.CHANGE_PASSWORD_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false
      });
    },
    [actions.CHANGE_PASSWORD_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false
      });
    },
    [actions.FORGOT_PASSWORD]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true
      });
    },
    [actions.FORGOT_PASSWORD_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false
      });
    },
    [actions.FORGOT_PASSWORD_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false
      });
    },
    [actions.NEW_PASSWORD]: (state, action) => {
      return freeze({
        ...state,
        isLoading: true
      });
    },
    [actions.NEW_PASSWORD_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false
      });
    },
    [actions.NEW_PASSWORD_FAILED]: (state, action) => {
      return freeze({
        ...state,
        isLoading: false
      });
    }
  },
  initialState
);
