/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import { createAction } from 'redux-actions';

export const FIND_USER_USER = createAction('ADMIN_USER/FIND_USER_USER');

export const FIND_ALL_SCHEDULE = createAction('ADMIN_USER/FIND_ALL_SCHEDULE');
export const FIND_REPORT_SCHEDULE_BOOKING = createAction('ADMIN_USER/FIND_REPORT_SCHEDULE_BOOKING');
export const FIND_REPORT_SCHEDULE_CANCEL = createAction('ADMIN_USER/FIND_REPORT_SCHEDULE_CANCEL');
export const HANDLE_SEARCH_REPORT_SCHEDULE = createAction(
  'ADMIN_USER/HANDLE_SEARCH_REPORT_SCHEDULE'
);

export const CLEAR = createAction('ADMIN_USER/CLEAR_ADMIN_USER');
