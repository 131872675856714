/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import { createAction } from 'redux-actions';

export const GET_ONE_QUESTION_SUCCESS = createAction('QUESTION/GET_ONE_QUESTION_SUCCESS');
export const GET_ALL_REPLY_SUCCESS = createAction('QUESTION/GET_ALL_REPLY_SUCCESS');

export const CLEAR = createAction('QUESTION/CLEAR_QUESTION');
