export const handlePagination = ({ take, skip, total, ...pagination }) => ({
  take: take,
  skip: skip,
  totalPage: Math.floor(total / take) > 0 ? Math.floor(total / take) : 1,
  canPreviousPage: skip > 0,
  canNextPage: Math.floor(total / take) > skip + 1,
  nextPage: skip + 1,
  previousPage: skip - 1
});
export const EGender = {
  male: 'male',
  female: 'female',
  other: 'other'
};
