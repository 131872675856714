import React, { useContext, useState } from 'react';
import { ListGroup, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ChatList from './ChatList';
import { ConfigContext } from '../../../../contexts/ConfigContext';
import avatar1 from '../../../../assets/images/user/avatar-1.jpg';
import * as cookies from '../../../../services/controls';
import { name } from '../../../../screens/Authentication';

const NavRight = () => {
  const configContext = useContext(ConfigContext);
  const { rtlLayout } = configContext.state;

  const [listOpen, setListOpen] = useState(false);

  const { user } = useSelector((state) => state[name]);

  const handleLogout = async () => {
    try {
      await cookies.clear();
      global.location.reload();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <ListGroup
        as="ul"
        bsPrefix=" "
        className="navbar-nav ml-auto"
        id="navbar-right"
        style={{ zIndex: 0 }}>
        <ListGroup.Item as="li" bsPrefix=" ">
          <Dropdown alignRight={!rtlLayout} className="drp-user">
            <Dropdown.Toggle as={Link} variant="link" to="#" id="dropdown-basic">
              <i className="icon feather icon-settings" />
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight className="profile-notification">
              <div className="pro-head">
                <img src={avatar1} className="img-radius" alt="User Profile" />
                <span>
                  <b>
                    {user?.lastname} {user?.firstname}
                  </b>
                </span>
              </div>
              <ListGroup as="ul" bsPrefix=" " variant="flush" className="pro-body">
                {/* <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="/profile" className="dropdown-item">
                    <i className="feather icon-user" />
                    Profile
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="/auth/change-password" className="dropdown-item">
                    <i className="feather icon-lock" /> Change password
                  </Link>
                </ListGroup.Item> */}
                <ListGroup.Item as="li" bsPrefix=" ">
                  <Link to="#" className="dropdown-item" onClick={handleLogout}>
                    <i className="feather icon-log-out" /> Sign Out
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Dropdown.Menu>
          </Dropdown>
        </ListGroup.Item>
      </ListGroup>
      <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
    </React.Fragment>
  );
};

export default NavRight;
