/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import { lazy } from 'react';

export const ROUTER_SIGN_IN = '/app/auth/signin';
export const ROUTER_SIGN_UP = '/app/auth/signup';
export const ROUTER_CHANGE_PASSWORD = '/app/auth/change-password';
export const ROUTER_FORGOT_PASSWORD = '/app/auth/forgot-password';
export const ROUTER_NEW_PASSWORD = '/app/auth/new-password';
export const ROUTER_PROFILE = 'AUTHENTICATION_PROFILE';

const routers = [
  {
    exact: true,
    // guard: GuestGuard,
    path: ROUTER_SIGN_IN,
    component: lazy(() => import('./views/SignIn'))
  },
  {
    exact: true,
    path: ROUTER_SIGN_UP,
    component: lazy(() => import('./views/SignUp'))
  },
  {
    exact: true,
    path: ROUTER_CHANGE_PASSWORD,
    component: lazy(() => import('./views/ChangePassword'))
  },
  {
    exact: true,
    path: ROUTER_FORGOT_PASSWORD,
    component: lazy(() => import('./views/ForgotPassword'))
  },
  {
    exact: true,
    path: ROUTER_NEW_PASSWORD,
    component: lazy(() => import('./views/NewPassword'))
  }
];
export default routers;
