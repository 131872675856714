/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import RootReducer from './reducers.js';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();
const middlewares = [sagaMiddleware, thunk];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(loggerMiddleware);
}
export default createStore(RootReducer, {}, compose(applyMiddleware(...middlewares)));
sagaMiddleware.run(sagas);
