/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
export const formatCurrency = (value) =>
  parseFloat(value.toFixed(2))?.toLocaleString('it-IT', {
    style: 'currency',
    currency: 'USD'
  });

export const formatCurrencyNatation = (value) => formatter.format(parseFloat(value.toFixed(2)));

export const handlePagination = ({ take, skip, total, ...pagination }) => ({
  take: take,
  skip: skip,
  totalPage: Math.floor(total / take) > 0 ? Math.floor(total / take) : 1,
  canPreviousPage: skip > 0,
  canNextPage: Math.floor(total / take) > skip + 1,
  nextPage: skip + 1,
  previousPage: skip - 1
});
