/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import { createAction } from 'redux-actions';

export const FIND_ALL_ITEM = createAction('ADMIN_ITEM/FIND_ALL_ITEM');
export const FIND_ONE_ITEM = createAction('ADMIN_ITEM/FIND_ONE_ITEM');
export const FIND_REPORT_ITEM = createAction('ADMIN_ITEM/FIND_REPORT_ITEM');
export const HANDLE_PAGINATION = createAction('ADMIN_ITEM/HANDLE_PAGINATION');
export const CLEAR = createAction('ADMIN_ITEM/CLEAR_ADMIN_ITEM');
