/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import { combineReducers } from '@reduxjs/toolkit';
import { reducer as formReducer } from 'redux-form';
import Authen, { name as nameOfAuthen } from '../screens/Authentication';
import Question, { name as nameOfQuestion } from '../screens/Admin/Questions';
import Dashboard, { name as nameOfDashboard } from '../screens/Admin/Dashboard';
import Users, { name as nameOfUsers } from '../screens/Admin/Users';
import Items, { name as nameOfItems } from '../screens/Admin/Item';

const reducers = combineReducers({
  form: formReducer,
  [nameOfAuthen]: Authen,
  [nameOfQuestion]: Question,
  [nameOfDashboard]: Dashboard,
  [nameOfItems]: Items,
  [nameOfUsers]: Users
});

export default reducers;
