/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (react query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Manager User
 */
import { lazy } from 'react';

export const ROUTER_USER = '/app/admin/users';
export const ROUTER_USER_DETAIL = '/app/admin/users/:id';
export const MENU_USER = 'user';

export const ROUTER_REQUEST_DOCTOR = '/app/admin/request-doctor';
export const MENU_REQUEST_DOCTOR = 'request_doctor';

export const ROUTER_KYC_USER = '/app/admin/kyc-user';
export const MENU_KYC_USER = 'kyc-user';

export const ROUTER_DASHBOARD_ADMIN = '/app/admin/dashboard';
export const MENU_DASHBOARD_ADMIN = 'dashboard';

export const ROUTER_QUESTION = '/app/admin/question';
export const ROUTER_QUESTION_DETAIL = '/app/admin/question/:id';
export const MENU_QUESTION = 'question';

export const ROUTER_ITEM = '/app/admin/item';
export const ROUTER_ITEM_DETAIL = '/app/admin/item/:id';
export const MENU_ITEM = 'item';

export const ROUTER_TRANSACTION = '/app/admin/transaction';
export const ROUTER_TRANSACTION_DETAIL = '/app/admin/transaction/:id';
export const MENU_TRANSACTION = 'transaction';

const routers = [
  {
    exact: true,
    path: ROUTER_KYC_USER,
    component: lazy(() => import('./Kyc/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_USER,
    component: lazy(() => import('./Users/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_USER_DETAIL,
    component: lazy(() => import('./Users/views/Dashboard'))
  },
  {
    exact: true,
    path: ROUTER_REQUEST_DOCTOR,
    component: lazy(() => import('./RequestDoctor/views/Container'))
  },

  {
    exact: true,
    path: ROUTER_DASHBOARD_ADMIN,
    component: lazy(() => import('./Dashboard/views/Dashboard'))
  },
  {
    exact: true,
    path: ROUTER_QUESTION,
    component: lazy(() => import('./Questions/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_QUESTION_DETAIL,
    component: lazy(() => import('./Questions/views/TaskDetails'))
  },
  {
    exact: true,
    path: ROUTER_ITEM,
    component: lazy(() => import('./Item/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_ITEM_DETAIL,
    component: lazy(() => import('./Item/views/Dashboard'))
  },
  {
    exact: true,
    path: ROUTER_TRANSACTION,
    component: lazy(() => import('./Transaction/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_TRANSACTION_DETAIL,
    component: lazy(() => import('./Transaction/views/Dashboard'))
  }
];
export default routers;
