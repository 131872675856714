/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import main from './main';
import { fork, all } from 'redux-saga/effects';

export default function* rootSaga() {
  yield all(main.map((saga) => fork(saga)));
}
