/**
 * @author Thanh Tho
 * @company 10minutes Ltc
 * @Link (react query): https://react-query.tanstack.com/graphql
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Manager User
 */
import { lazy } from 'react';

export const ROUTER_NATION = '/app/admin/nation';
export const MENU_NATION = 'Nations';

export const ROUTER_PROVINCE = '/app/admin/province';
export const MENU_PROVINCE = 'Province';

export const ROUTER_DISTRICT = '/app/admin/district';
export const MENU_DISTRICT = 'District';

export const ROUTER_HOSPITAL = '/app/admin/hospital';
export const MENU_HOSPITAL = 'Hospital';

export const ROUTER_LANGUAGE = '/app/admin/language';
export const MENU_LANGUAGE = 'Language';

export const ROUTER_CURRENCY = '/app/admin/currency';
export const MENU_CURRENCY = 'Currency';

export const ROUTER_SPECIALIST = '/app/admin/specialist';
export const ROUTER_DETAIL_SPECIALIST = '/app/admin/specialist/:id';
export const MENU_SPECIALIST = 'specialist';

export const ROUTER_NEW = '/app/admin/new';
export const ROUTER_DETAIL_NEW = '/app/admin/new/:id';
export const MENU_NEW = 'new';

export const ROUTER_CATEGORY = '/app/admin/category';
export const MENU_CATEGORY = 'Category';

const routers = [
  {
    exact: true,
    path: ROUTER_NATION,
    component: lazy(() => import('./Nation/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_PROVINCE,
    component: lazy(() => import('./Province/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_DISTRICT,
    component: lazy(() => import('./District/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_HOSPITAL,
    component: lazy(() => import('./Hospital/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_LANGUAGE,
    component: lazy(() => import('./Language/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_CURRENCY,
    component: lazy(() => import('./Currency/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_SPECIALIST,
    component: lazy(() => import('./Specialist/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_DETAIL_SPECIALIST,
    component: lazy(() => import('./Specialist/views/Form'))
  },
  {
    exact: true,
    path: ROUTER_NEW,
    component: lazy(() => import('./New/views/Container'))
  },
  {
    exact: true,
    path: ROUTER_DETAIL_NEW,
    component: lazy(() => import('./New/views/Form'))
  },
  {
    exact: true,
    path: ROUTER_CATEGORY,
    component: lazy(() => import('./Category/views/Container'))
  }
];
export default routers;
