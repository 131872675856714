/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import cookie from 'js-cookie';

export const get = async (name) => await cookie.get(name);

export const set = async (name, value) => {
  try {
    await cookie.set(name, value, { expires: 1 / 2 });
    return true;
  } catch (err) {
    throw err;
  }
};

export const clear = () => {
  cookie.remove('accessToken');
  cookie.remove('refreshToken');
};
