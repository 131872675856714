/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
// import { call, put } from "redux-saga/effects";
import * as actions from '../actions';
import { takeAction } from '../../../services/forkActionSagas';

export function* LOGINCallBack(action) {
  // try {
  //     console.log("LOGIN", action.payload);
  //     const { data } = action.payload;
  //     yield call(apis, data)                   //call apis
  //     yield put(actions.LOGIN_SUCCESS(data));  // dispatch action to redux
  // } catch (error) {
  //     yield put(actions.LOGIN_FAILED(error));
  // }
}
export function* LOGIN() {
  yield takeAction(actions.LOGIN, LOGINCallBack);
}
export default [LOGIN];
