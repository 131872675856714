import axios from 'axios';
import * as cookie from './controls';
/**
 * @url : String
 * @method : GET | POST | PUT | PATCH | DELETE
 * @data : Object
 * @headers : Object
 */
export const EMethod = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE'
};

const handleResponse = async (data) => {
  if (data.data && data.data.accessToken) cookie.set('accessToken', data.data.accessToken);
  if (data.data && data.data.refreshToken) cookie.set('refreshToken', data.data.refreshToken);
  return await data;
};

const handleError = (error) => {
  if (error.response && error.response.data) throw new Error(JSON.stringify(error.response.data));
  throw error;
};
export const send = async ({ url, method, data, headers }) => {
  try {
    let isValid = true;
    if (!url) isValid = false;
    if (
      method.toUpperCase() !== EMethod.GET &&
      method.toUpperCase() !== EMethod.POST &&
      method.toUpperCase() !== EMethod.PUT &&
      method.toUpperCase() !== EMethod.PATCH &&
      method.toUpperCase() !== EMethod.DELETE
    )
      isValid = false;

    if (!isValid) return { success: false, message: 'Invalid Input' };
    const accessToken = await cookie.get('accessToken');
    const res = await axios({
      method,
      url,
      headers: {
        ...headers,
        accept: 'application/json',
        'Content-Type': ' application/json',
        debug: 'T',
        Authorization: `Bearer ${accessToken}`
      },
      data: method.toUpperCase() !== EMethod.GET ? data : null,
      params: method.toUpperCase() === EMethod.GET ? data : null
    });

    return await handleResponse(res.data);
  } catch (error) {
    return await handleError(error);
  }
};
