/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import * as actions from './actions';
import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
// import { handlePagination } from './utils';

export const initialState = freeze({
  replies: [],
  userData: {},
  dataSchedule: [],
  dataSKDBooking: [],
  dataSKDCancel: [],
  dataSKDSuccess: [],
  listSchedule: [],
  pagination: {
    take: 50,
    skip: 1,
    total: 0,
    startDate: new Date(),
    endDate: new Date(),
    type: 'day',
    patientId: null,
    role: null
  }
});

export const name = 'ADMIN_USER';

export default handleActions(
  {
    [actions.FIND_USER_USER]: (state, action) => {
      return freeze({
        ...state,
        userData: action.payload
      });
    },
    [actions.FIND_REPORT_SCHEDULE_BOOKING]: (state, action) => {
      return freeze({
        ...state,
        dataSKDBooking: action.payload.dataBooking,
        dataSKDCancel: action.payload.dataCancel,
        dataSKDSuccess: action.payload.dataSuccess,
        listSchedule: action.payload.listSchedule
      });
    },
    [actions.FIND_REPORT_SCHEDULE_CANCEL]: (state, action) => {
      return freeze({
        ...state,
        dataSKDCancel: action.payload
      });
    },
    [actions.HANDLE_SEARCH_REPORT_SCHEDULE]: (state, action) => {
      return freeze({
        ...state,
        pagination: action.payload
      });
    },

    [actions.CLEAR]: () => {
      return freeze(initialState);
    }
  },
  initialState
);
