/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import * as actions from './actions';
import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import { handlePagination } from './utils';

export const initialState = freeze({
  data: {},
  items: [],
  queryItem: null,
  pagination: {
    take: 50,
    skip: 1,
    total: 0
  }
});

export const name = 'ADMIN_ITEM';

export default handleActions(
  {
    [actions.FIND_ALL_ITEM]: (state, action) => {
      return freeze({
        ...state,
        items: action.payload.data,
        pagination: handlePagination(action.payload.pagination)
      });
    },
    [actions.FIND_ONE_ITEM]: (state, action) => {
      return freeze({
        ...state,
        data: action.payload.data,
        queryItem: action.payload.queryItem
      });
    },
    [actions.CLEAR]: () => {
      return freeze(initialState);
    }
  },
  initialState
);
