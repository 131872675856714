/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import * as actions from './actions';
import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';

export const initialState = freeze({
  dataHeader: null,
  userData: null,
  pagination: { limit: 50, offset: 0, type: 'day' },
  dataProfit: null,
  dataUser: null,

  listSchedule: []
});

export const name = 'DASHBOARD';

export default handleActions(
  {
    [actions.GET_HEADER]: (state, action) => {
      return freeze({
        ...state,
        dataHeader: action.payload
      });
    },
    [actions.GET_PROFIT_CHART]: (state, action) => {
      return freeze({
        ...state,
        dataProfit: action.payload
      });
    },
    [actions.GET_USER_CHART]: (state, action) => {
      return freeze({
        ...state,
        dataUser: action.payload
      });
    },
    [actions.HANDLE_SEARCH_CHART]: (state, action) => {
      return freeze({
        ...state,
        dataProfit: null,
        pagination: action.payload
      });
    },
    [actions.CLEAR]: (state, action) => {
      return freeze(initialState);
    }
  },
  initialState
);
