import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';

import * as actions from '../../screens/Authentication/actions';
import { APIS_FIND_ONE_USER_INFOR } from '../../screens/Authentication/apis';
import { name } from '../../screens/Authentication';
import { ROUTER_SIGN_IN } from '../../screens/Authentication/router';
import * as cookies from '../../services/controls';

const AuthGuard = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn } = useSelector((state) => state[name]);
  const { isError, isLoading, isSuccess, data, error } = useQuery(
    'get_profile',
    APIS_FIND_ONE_USER_INFOR
  );

  useEffect(() => {
    const checkExistToken = async () => {
      const accessToken = await cookies.get('accessToken');
      if (!accessToken) history.push(ROUTER_SIGN_IN);
    };
    checkExistToken();
  }, []);

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(actions.LOGIN_SUCCESS(data.data));
    } else if (!isLoading && isError) {
      dispatch(actions.LOGIN_FAILED(error));
      history.push(ROUTER_SIGN_IN);
    }
  }, [isLoading, data]);

  return <React.Fragment>{children}</React.Fragment>;
};

export default AuthGuard;
