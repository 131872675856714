/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import * as actions from './actions';
import freeze from 'deep-freeze';
import { handleActions } from 'redux-actions';
import { handlePagination } from './utils';

export const initialState = freeze({
  replies: [],
  pagination: { take: 10, skip: 0, questionId: 0 },
  questionData: {}
});

export const name = 'QUESTION';

export default handleActions(
  {
    [actions.GET_ONE_QUESTION_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        questionData: action.payload
      });
    },
    [actions.GET_ALL_REPLY_SUCCESS]: (state, action) => {
      return freeze({
        ...state,
        replies: action.payload.data,
        pagination: handlePagination(action.payload.pagination)
      });
    },
    [actions.CLEAR]: (state, action) => {
      return freeze(initialState);
    }
  },
  initialState
);
