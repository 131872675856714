/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';

// import Loader from "./components/Loader/Loader";
import AdminLayout from './layouts/AdminLayout';
import AuthGuard from './components/Auth/AuthGuard';
import Authenticate from './screens/Authentication/router';
import AdminRouter from './screens/Admin/router';
import ModulesRouter from './screens/Modules/router';

export const renderRoutes = (routes = []) => (
  <Suspense fallback="Loading ...">
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? renderRoutes(route.routes) : <Component {...props} />}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/NotFound404'))
  },
  {
    exact: true,
    path: '/maintenance/coming-soon',
    component: lazy(() => import('./views/maintenance/ComingSoon'))
  },
  {
    exact: true,
    path: '/maintenance/error',
    component: lazy(() => import('./views/maintenance/Error'))
  },
  {
    exact: true,
    path: '/blog/:id',
    component: lazy(() => import('./screens/Modules/New/views/Detail'))
  },
  ...Authenticate,
  {
    path: '*',
    layout: AdminLayout,
    guard: AuthGuard,
    routes: [...AdminRouter, ...ModulesRouter]
  }
];

export default routes;
