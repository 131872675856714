/**
 * @author Thanh Tho
 * @company EG Group Ltc
 *          Author          Branch          Version            Comment
 *          Thanh Tho        master          1.0.0              Initital project
 */
import { all } from 'redux-saga/effects';
import { sagas as Authen } from '../screens/Authentication';
// import { sagas as Employees } from '../screens/employees';

export default function* rootSaga() {
  yield all([Authen()]);
}
